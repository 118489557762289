export default {
    name: "Glossary",
    created () {
        this.$nextTick(function () {
            this.relevantData('A', 0)
            this.GitGlossarySeek(this.GlossaryData[0])
            this.ariseCombobox('#comboboxGlossary')
        });
    },
    mounted () {
        this.$nextTick(function () {
            // this.$$.navbarAnimation()
            console.log(this.$router);

        })
    },
    data () {
        return {
            GlossaryShwoData: [],
            GlossaryData: [
                [{
                    letter: 'A',
                    data: [{
                        name: 'ALPHA',
                        content: `Alpha is a measure of the performance of an investment as compared to a suitable benchmark index. An alpha of one (the baseline value is zero) shows that the return on the investment during a specified time frame outperformed the overall market average by 1%. A negative alpha number reflects an investment that is underperforming as compared to the market average.`
                    }, {
                        name: 'AMERICAN-STYLE',
                        content: 'A warrant that may be exercised on any market day up to and including the expiry date.'
                    },
                    {
                        name: 'ASK VOLUME',
                        content: 'Ask volume is the quantity of a security that sellers are willing to sell at a specified ask price. Ask volume is stated as board lots with each representing 100 units (shares) each.'
                    },
                    {
                        name: 'ASK PRICE',
                        content: 'Ask price is the lowest price a seller is willing to sell a particular security.'
                    },
                    {
                        name: 'AT-THE-MONEY',
                        content: `A situation where the warrant's exercise price is close to or identical to the underlying asset price. An at-the-money warrant has no intrinsic value, but may still have some time value (depends on time to maturity).For example if XYZ stock is trading at RM2.00, then the call option or put option with the exercise price of RM2.00 are said to be at-the-money.`
                    }
                    ]
                },
                {
                    letter: 'B',
                    data: [{
                        name: 'BEAR MARKET',
                        content: `A stock market in which sellers dominate, resulting in generally falling prices.`
                    },
                    {
                        name: 'BID VOLUME',
                        content: `Bid volume is the quantity of a security that buyers are willing to buy at a specified bid price. Bid volume is stated as board lots with each representing 100 units (shares) each.`
                    },
                    {
                        name: 'BID PRICE',
                        content: `Bid price is the highest price a buyer is willing to purchase a particular security. `
                    },
                    {
                        name: 'BLACK-SCHOLES MODEL',
                        content: `One of the most commonly used option pricing models.`
                    },
                    {
                        name: 'BOARD LOT',
                        content: `The minimum number of warrants to trade in Bursa Malaysia. 1 board lot = 100 units of warrants.`
                    },
                    {
                        name: 'BONUS ISSUE',
                        content: `Bonus Issues are shares distributed by a company to its current shareholders as fully paid shares free of charge. Instead of a dividend payout, companies offer to distribute additional shares to the shareholders. For example, the company may decide to give out one bonus share for every ten shares held by existing shareholders.`
                    },
                    {
                        name: 'BROKER',
                        content: `An agent, authorised to buy and sell shares on behalf of a client.`
                    },
                    {
                        name: 'BULL MARKET',
                        content: `A stock market in which buyers dominate and where prices are on a rising trend.`
                    },
                    {
                        name: 'BREAK-EVEN LEVEL',
                        content: `Refers to the price at which a transaction produces neither a gain nor a loss.`
                    }
                    ]
                },
                {
                    letter: 'C',
                    data: [{
                        name: 'CALL WARRANTS',
                        content: `A call warrant offers its holder the right, but not obligation to purchase a certain amount of the underlying asset on a pre-determined exercise price within a pre-determined time.`
                    },
                    {
                        name: 'CALLABLE BULL/BEAR CONTRACTS (CBBC)',
                        content: `CBBC tracks the performance of an Underlying Asset without requiring investors to pay the full price required to own the actual stock. They are issued either as Bull or Bear certificates with a fixed expiry date, allowing investors to take bullish or bearish positions on the underlying stock with the possibility of an early termination before the expiry date when the underlying moves in contrary direction to investors' expectations.`
                    },
                    {
                        name: 'CASH SETTLEMENT AMOUNT',
                        content: `The positive amount received from the exercise of the warrant at expiry. In the event the Cash Settlement Amount is less than or equal to zero, warrantholders shall not be entitled to receive any payment in respect of the structured warrants.`
                    },
                    {
                        name: 'CENTRAL DEPOSITORY SYSTEM (CDS) ACCOUNT',
                        content: `Investors who wish to trade in securities listed on Bursa Malaysia must open Central Depository System (CDS) accounts with Authorised Depository Agents (ADAs), i.e. stockbroking companies. Securities bought will be credited into CDS accounts that the investors have opened. Likewise for securities sold, these securities will be debited from the CDS accounts. <br/>
                                Investors can use the CDS to transfer securities from one CDS account to another, provided the transfers are within the reasons approved by Bursa Malaysia Depository Sdn Bhd. Other transactions that depositors can perform via the CDS are registering of bank account information, updating of account particulars, reactivation of dormant CDS account, reactivation of inactive CDS account, deposit of share certificate, withdrawal of shares of delisted company and closing of CDS account.`
                    }
                    ]
                },
                {
                    letter: 'D',
                    data: [{
                        name: 'DELTA',
                        content: `Delta is the ratio of the change in the warrant to the change in the underlying asset’s price. It shows the absolute changes in the price of the warrant, if the price of the underlying asset changes.<br/><br/><br/>Delta normally ranges between 0 and 1 for call warrants and 0 and -1 for put warrants. For example (assuming a warrant with conversion ratio of 1) a delta of 0.50 implies that if the value of the underlying changes by 20 cents, then the value of the call warrant should change by 10 cents.`
                    },
                    {
                        name: `DAY'S HIGH/ HIGH PRICE`,
                        content: `High price refers to a security's intraday high trading price. High price indicates the stock's highest price traded throughout one day of trading activity during regular trading hours.`
                    },
                    {
                        name: 'DISCOUNT',
                        content: `The amount by which the price of a share is quoted below its paid-up value.`
                    },
                    {
                        name: 'DIVIDEND YIELD',
                        content: `The amount of a company's annual dividend expressed as a percentage of the current price of the share of that company.`
                    }
                    ]
                },
                {
                    letter: 'E',
                    data: [{
                        name: 'EARNINGS PER SHARE',
                        content: `The amount of a company's annual profits or earning attributable to each ordinary share of that company.`
                    },
                    {
                        name: 'EFFECTIVE GEARING',
                        content: `Measures the theoretical percentage change in warrant price for every 1% change in the underlying price. For example, effective gearing of 10 times, other factors being equal, means for every 1% change in underlying price, the warrant price moves by 10%.`
                    },
                    {
                        name: 'EXCHANGE TRADING HOURS',
                        content: `The equities trading hours are divided into two sessions for both normal and odd lot market.<br/>
                                For Malaysia: The first session is from 8.30am – 12.30pm, and the second session runs from 2.00pm – 5.00pm.<br/>
                                For Hong Kong: The first session is from 9:00am - 12:00pm, and the second session runs from 1:00pm - 4:00pm.<br/>
                                For Thailand: The first session open at random between 9:55 to 10:00am - 12:30pm, and the second session open at random between 2:25 to 2:30pm and closes at random between 4:35 to 4:40pm.`
                    },
                    {
                        name: 'EUROPEAN-STYLE',
                        content: `A warrant that is automatically exercised on the predetermined expiry date.`
                    },
                    {
                        name: 'EXERCISE PRICE/LEVEL',
                        content: `The predetermined price/level for the warrant holder to buy or sell the underlying of the warrant. It is used to calculate the cash settlement amount of a cash-settled warrant at the expiry.`
                    },
                    {
                        name: 'EXERCISE RATIO',
                        content: `The number of warrants needed to exchange for one unit of the Underlying Asset.`
                    },
                    {
                        name: 'EXCHANGE TRADED FUNDS (ETF)',
                        content: `ETF is the acronym for an innovative financial product known as Exchange Traded Fund. It is an open-ended investment fund listed and traded on a stock exchange. ETF combines the features of an Index fund and a stock. <br/><br/>
                
                                Generally, there are three types of ETFs: equity ETFs, Leveraged & Inverse ETFs, fixed income ETFs and commodity ETFs. These ETFs consists of baskets of stocks, bonds, futures or commodities based on an index which instantly offers broad diversification and avert the risk involved in owning stock of a single company.<br/><br/>
                                
                                The liquidity of an ETF reflects the liquidity of the underlying basket of shares.`
                    },
                    {
                        name: 'EXPIRY DATE',
                        content: `The date the warrant expires; where the right of the warrant holder to exercise the warrant ceases.`
                    }
                    ]
                },
                {
                    letter: 'F',
                    data: [{
                        name: 'FUTURES',
                        content: `Futures are financial contracts obligating the buyer to purchase an asset or the seller to sell an asset.`
                    }]
                },
                {
                    letter: 'G',
                    data: [{
                        name: 'GAMMA',
                        content: `Measures the changes of a warrant's delta as the price of the underlying asset changes, all other factors remain constant.`
                    },
                    {
                        name: 'GEARING',
                        content: `Gearing is the ratio of the stock price to the warrant price and represents the leverage that the warrant offers.<br/><br/>
                
                                For example, a warrant with gearing of 10x will have 10 times more exposure than if you purchased the underlying asset. To estimate the increase or decrease in the warrant price in relative to the underlying asset price, we should look at effective gearing.`
                    },
                    {
                        name: 'GROWTH STOCKS',
                        content: `Shares of companies with good prospects for increasing profits and capital size. Likely to bring shareholders future capital gains through a share price rise, high dividends, share bonuses or rights issues.`
                    }
                    ]
                },
                {
                    letter: 'H',
                    data: [{
                        name: 'HEDGE',
                        content: `Hedging is a strategy to reduce the risk of adverse price movements in an asset. Normally, a hedge consists of taking an offsetting position in a related security to protect the value of an asset or a portfolio.`
                    },
                    {
                        name: `DAY'S HIGH/ HIGH PRICE`,
                        content: `High price refers to a security's intraday high trading price. High price indicates the stock's highest price traded throughout one day of trading activity during regular trading hours.`
                    },
                    {
                        name: 'HISTORICAL VOLATILITY',
                        content: `Used as a reference to assess the previous price fluctuations of the underlying asset. Normally expressed in number of days, e.g. 10-day volatility, 90-day volatility etc.`
                    }
                    ]
                },
                {
                    letter: 'I',
                    data: [{
                        name: `IMPLIED VOLATILITY`,
                        content: `Most important factor that affects the price of structured warrants. It refers to the estimate of future price volatility of a specified underlying and is used by the market as an indicator to decide whether a warrant is cheap or expensive. With all factors being equal, the higher the implied volatility, the higher the warrant price.<br/><br/>
                
                                For example, a RM1.00 SW that fluctuates at RM0.10 has higher implied volatility than a RM1.00 SW that fluctuates at RM0.01.`
                    },
                    {
                        name: `INDICES`,
                        content: `In relation to a stock exchange, index is a measures of a stock market, or a subset of the stock market, that helps investors compare current price levels with past prices to calculate market performance.`
                    },
                    {
                        name: `INITIAL PUBLIC OFFERING (IPO)`,
                        content: `An initial public offering (IPO) occurs when a company first sells common shares to investors in the public. Generally, the company offers primary shares this way, although sometimes, secondary shares are also sold as IPOs. For a company to offer IPOs, they need to hire a corporate lawyer as well as an investment banker to underwrite the offer. The actual sale of the shares is generally offered by the stock exchange or by regulators. When the company starts to offer IPOs, they are usually required to reveal financial information about the company so that investors can make an informed decision.`
                    },
                    {
                        name: `INSTRUMENT`,
                        content: `A tradeble asset or negotiable item such as a security, commodity, derivative or index, or any item that underlies a derivative. An instrument is a means by which something of value is transferred or held.`
                    },
                    {
                        name: `IN-THE-MONEY`,
                        content: `An in-the-money option has intrinsic value more than zero.<br/><br/>
                
                                For call warrant, it is said to be in-the-money if Underlying Price > Exercise Price;<br/>
                                For put warrant, it is said to be in-the-money if Exercise Price > Underlying Price.`
                    },
                    {
                        name: `INTRADAY MOVEMENT`,
                        content: `Intraday is defined as within the day. Intraday movement refers to the price movements of a specific security throughout one day of trading activity during regular trading hours. It is to express the high and low price of a stock in its trading day. `
                    },
                    {
                        name: `INTRINSIC VALUE`,
                        content: `Intrinsic value for a warrant is the difference between the price of the Underlying Asset and the exercise or strike price. The intrinsic value can be zero, but it can never be negative.`
                    },
                    {
                        name: `ISSUER`,
                        content: `The entity that issues warrants, usually a financial institution, e.g. Maybank Investment Bank Berhad.`
                    },
                    {
                        name: `INTRADAY'S LOW/ LOW PRICE`,
                        content: `Intraday low refers to the lowest price at which a security trades on a given trading day. It can be lower than the closing price and is also called the Day low.`
                    }
                    ]
                },
                {
                    letter: 'J',
                    data: []
                },
                {
                    letter: 'K',
                    data: []
                },
                {
                    letter: 'L',
                    data: [{
                        name: `LAST PRICE`,
                        content: `Last price refers to the last traded price of the particular security. Last price represents at what price points there are willing buyers and sellers and where the most recent trade occured.`
                    },
                    {
                        name: `LAST TRADING DAY`,
                        content: `Three (3) market days immediately before the expiry date of the warrant.`
                    },
                    {
                        name: `LIMIT ORDER`,
                        content: `To avoid buying or selling a stock at a price higher or lower than what is wanted, it's best to place a limit order rather than a market order. A limit order is an order to buy or sell an instrument at a specific price. A buy limit order can only be executed at the limit price or lower, and a sell limit order can only be executed at the limit price or higher. When a market order is placed, the price at which an order will be filled can't be controlled.`
                    },
                    {
                        name: `LIQUIDITY OF THE MARKET`,
                        content: `The state of affairs in a stock market in which it is generally easy to convert securities into cash and vice versa, without causing a movement in prices.`
                    },
                    {
                        name: `LISTING DAY`,
                        content: `The day the warrant is listed and quoted on the exchange.`
                    },
                    {
                        name: `LIQUIDITY PROVIDER`,
                        content: `Refer to MARKET MAKER.`
                    },
                    {
                        name: `LIVE MATRIX`,
                        content: `Live matrix is a live price matrix feed from Maybank Investment Bank's market making system. It allows investors to view bids and offer prices for a warrant based on various price points in the stock or index.<br/><br/>
                
                                The warrant prices in the Live Matrix are indicative. The actual warrant prices on the market may differ from the prices displayed on the website due to several factors.`
                    },
                    {
                        name: `INTRADAY'S LOW/ LOW PRICE`,
                        content: `Intraday low refers to the lowest price at which a security trades on a given trading day. It can be lower than the closing price and is also called the Day low.`
                    }
                    ]
                },
                {
                    letter: 'M',
                    data: [{
                        name: `MARKET CAPITALISATION`,
                        content: `The total value of a listed company's shares based on current market price.`
                    },
                    {
                        name: `MARKET MAKER`,
                        content: `Market maker provides liquidity by posting bid and offer prices on the trading system of Bursa Securities on each market day over the life of the structured warrant. Market maker can be the issuer itself (e.g. Maybank Investment Bank Berhad) or an appointed designated market maker.`
                    },
                    {
                        name: `MARKET ORDER`,
                        content: `A market order is an order to buy or sell a stock at the current market price. The advantage of a market order is that it is almost always guaranteed that the order will be executed (as long as there are willing buyers and sellers). Depending on a firm's commission structure, a market order may also be less expensive than a limit order.`
                    },
                    {
                        name: `MONEYNESS`,
                        content: `Relationship between exercise price and the underlying asset price. There are three forms of moneyness, which are In-the-Money, At-the-Money and Out-of-the-Money.`
                    }
                    ]
                },
                {
                    letter: 'N',
                    data: []
                },
                {
                    letter: 'O',
                    data: [{
                        name: `ODD LOTS`,
                        content: `This is an amount of shares that is smaller than the usual unit of trading in Malaysia; specifically, a quantity of less than 100 shares of stock in a transaction or a trade that is not divisible by 100.`
                    },
                    {
                        name: `OPEN PRICE`,
                        content: `Open price is the price at which a security first trades upon the opening of an exchange on a trading day. For example, Bursa Malaysia opens at precisely 9:00a.m, GMT+8. The price of the first trade for any listed stock is its daily open price.`
                    },
                    {
                        name: `ORDER BOOK`,
                        content: `Order Book is an 'electronic book' that shows the demand for the shares of the company at various prices on a real time basis.`
                    },
                    {
                        name: `OUT-OF-THE-MONEY`,
                        content: `An out-of-the-money option has intrinsic value equals to zero.<br/><br/>
                
                                For call warrant, it is said to be out-of-the-money if Underlying Price < Exercise Price;<br/>
                                For put warrant, it is said to be out-of-the-money if Exercise Price < Underlying Price.`
                    },
                    {
                        name: `OUTSTANDING WARRANT QUANTITY`,
                        content: `Outstanding warrant quantity refers to the quantity of warrants held by investors. Hedging by the issuer will only be executed for its outstanding positions. The higher the outstanding quantity, the higher the probability that the warrant price is affected by the forces of market supply and demand and vice versa. As a result, the warrant's price may not track the price of the underlying asset very closely.<br/><br/>
                
                                For Malaysia, to observe MIBB's outstanding warrant quantity for any speficied warrant (available at the end of all calendar month), investors may refer to the Market Information tab under the Announcements section for Structured Warrants on Bursa Malaysia's website.`
                    }
                    ]
                },
                {
                    letter: 'P',
                    data: [{
                        name: `PREMIUM`,
                        content: `Measures the percentage of the underlying asset has to rise (Call Warrant) or fall (Put Warrant) for a warrant holder to become break-even if he/she holds the warrant until expiry.`
                    },
                    {
                        name: `PROXY`,
                        content: `One who is given written authority to vote for and on behalf of a shareholder at a meeting of the company.`
                    }, {
                        name: `PUT WARRANT`,
                        content: `A put warrant offers its holder the right, but not obligation to sell a certain amount of the underlying asset on a pre-determined exercise price within a pre-determined time.`
                    }
                    ]
                },
                {
                    letter: 'Q',
                    data: [{
                        name: `QUOTATION`,
                        content: `The prices bid and offered by buyers and sellers for securities listed on a stock exchange.`
                    }]
                },
                {
                    letter: 'R',
                    data: [{
                        name: `REMISIER `,
                        content: `An agent of a stockbroking company who brings business to that company in return for a share of the brokerage or commission.`
                    },
                    {
                        name: `RIGHTS ISSUE`,
                        content: `Companies raise additional capital by offering to existing shareholders the rights to subscribe for new shares, at a price usually below the current market price. These Rights Issues have a market of their own and can be traded on any stock exchange.`
                    }
                    ]
                },
                {
                    letter: 'S',
                    data: [{
                        name: `SHARE SPLIT`,
                        content: `When a company reduces the market price of its shares by increasing the number of outstanding share in the market, e.g. 100,000 units of RM 2 ordinary shares would be split into 200,000 units of RM 1 ordinary shares.`
                    },
                    {
                        name: `SHARIAH-COMPLIANT SECURITIES`,
                        content: `Shariah-compliant securities are securities of a public-listed company which have been classified as Shariah permissible for investment.`
                    },
                    {
                        name: `SHORT SELLING`,
                        content: `The action of a person selling shares which he does not own at the time of selling.`
                    },
                    {
                        name: `STOP ORDER`,
                        content: `A Stop order becomes a market order to buy or sell securities once the specified stop price is attained or penetrated.`
                    },
                    {
                        name: `STRIKE PRICE`,
                        content: `The predetermined price/level for the warrant holder to buy or sell the underlying of the warrant. It is used to calculate the cash settlement amount of a cash-settled warrant at the expiry.`
                    },
                    {
                        name: `STRUCTURED WARRANT LIQUIDITY`,
                        content: `A structured warrant is considered liquid when there is:-<br/><br/>
                
                                i. Adequate trading volume; and/ or<br/>
                                ii. Sufficient depth in bid and ask price; and/ or<br/>
                                iii. Quantity during trading hours.`
                    },
                    ]
                },
                {
                    letter: 'T',
                    data: [{
                        name: `THEORETICAL CLOSING PRICE (TCP)`,
                        content: `Price at which an instrument would trade if it stops trading at the moment the price is calculated. TCP is calculated on a real-time basis in Pre-Closing/Closing Phase.`
                    },
                    {
                        name: `THEORETICAL OPENING PRICE (TOP)`,
                        content: `Price at which an instrument would trade if it opens at the moment the price is calculated. TOP is calculated on a real-time basis in Pre-Opening Phase.`
                    },
                    {
                        name: `TRADING AT LAST (TAL)`,
                        content: `Orders at TAL will only be entered and matched at the Closing Price. Only Limit orders are allowed and the system will reject any Market Orders.`
                    },
                    {
                        name: `THETA`,
                        content: `Measures the expected daily decline in the value of a warrant due to time decay, all other factors remain constant. Theta is always negative as time decay is always working against a warrant holder.`
                    },
                    {
                        name: `TIME DECAY`,
                        content: `Time Decay is the effects of Time value declining as Warrants move towards expiration.`
                    },
                    {
                        name: `TIME VALUE`,
                        content: `The difference between the warrant price and intrinsic value.`
                    }
                    ]
                },
                {
                    letter: 'U',
                    data: [{
                        name: `UNDERLYING ASSET`,
                        content: `The financial instrument referenced by the warrant.`
                    }]
                },
                {
                    letter: 'V',
                    data: [{
                        name: `VOLATILITY`,
                        content: `The uncertainty in the price movement which will affect the return of the underlying.<br/><br/>
                
                                Refer to HISTORICAL VOLATILITY and IMPLIED VOLATILITY.`
                    },
                    {
                        name: `VOLUME TRADED`,
                        content: `Volume traded refers to the total quantity of shares or warrants transacted between buyers and sellers of the specified security during a trading day throughout its regular trading hours.`
                    },
                    {
                        name: `VOLUME WEIGHTED AVERAGE PRICE (VWAP)`,
                        content: `The VWAP for a stock is calculated by adding the value traded for every transaction in that stock ("price" x "number of shares traded") and dividing it with the total number of shares traded. A VWAP can be computed for any given trading period.`
                    }
                    ]
                },
                {
                    letter: 'W',
                    data: [{
                        name: `WARRANT CALCULATOR`,
                        content: `Warrant calculator tool allows investors to gauge the theoretical price of a specific warrant based on a few inputs. Inputs needed are such as underlying price, date to maturity and/or implied volatility.`
                    },
                    {
                        name: `WARRANTS TRADING HOURS`,
                        content: `Trading hours for warrants over FBM KLCI and Malaysian securities are from 9.00am – 4.44pm, and 9.00am – 4.43pm for warrants over S&P 500.`
                    },
                    {
                        name: `WARRANT HOLDER`,
                        content: `The person whose names for the time being appear on the Record of Depositors for the structured warrants.`
                    },
                    {
                        name: `WARRANT`,
                        content: `A warrant gives the holder the right but not the obligation to buy or sell an underlying security at a certain price, quantity.`
                    },
                    {
                        name: `WARRANTS PROSPECTUS ("BASE PROSPECTUS")`,
                        content: `The document published by an Issuer in connection with the offering of Structured Warrants to be issued from time to time.`
                    },
                    ]
                },
                {
                    letter: 'X',
                    data: []
                },
                {
                    letter: 'Y',
                    data: []
                },
                {
                    letter: 'Z',
                    data: []
                }
                ],
                [{
                    letter: 'A',
                    data: [{
                        name: `ALPHA (ALPHA)`,
                        content: `Alpha adalah ukuran prestasi satu pelaburan berbanding dengan indeks penanda aras yang sesuai. Alpha satu (nilai asas adalah sifar) menunjukkan bahawa pulangan pelaburan dalam jangka masa yang ditentukan mengungguli pulangan purata pasaran keseluruhan sebanyak 1%. Nombor alpha negatif mencerminkan pelaburan yang berprestasi rendah berbanding dengan pulangan purata pasaran.`
                    },
                    {
                        name: `AKAUN SAHAM SISTEM DEPOSITORI PUSAT (CDS) (CENTRAL DEPOSITORY SYSTEM (CDS) ACCOUNT)`,
                        content: `Pelabur yang ingin berdagang dalam sekuriti yang disenaraikan Bursa Malaysia perlu membuka akaun saham Sistem Depositori Pusat (CDS) dengan Agen Depositori Bertauliah (ADA), i.e. syarikat broker saham. Sekuriti yang dibeli akan dikreditkan ke dalam akaun CDS yang telah dibuka oleh pelabur. Begitu juga sekuriti yang dijual akan didebitkan daripada akaun CDS. <br/>
                                Pelabur boleh menggunakan CDS untuk memindahkan sekuriti daripada satu akaun CDS kepada satu akaun CDS yang lain, asalkan pemindahan tersebut berasaskan sebab yang diluluskan oleh Bursa Malaysia Depository Sdn Bhd. Lain-lain transaksi yang boleh dilakukan oleh pendeposit menerusi CDS adalah mendaftarkan maklumat akaun bank, mengemaskini butiran akaun, mengaktifkan semula akaun CDS, mendeposit sijil saham, mengeluarkan saham syarikat yang telah dinyahsenarai dan menutup akaun CDS.`
                    },
                    {
                        name: `ASET PENDASAR (UNDERLYING ASSET)`,
                        content: `Instrumen kewangan yang dirujuk oleh waran.`
                    }
                    ]
                },
                {
                    letter: 'B',
                    data: [{
                        name: `BROKER SAHAM (BROKER)`,
                        content: `Seorang ejen yang diberi kuasa untuk membeli dan menjual saham bagi pihak pelanggan.`
                    },
                    {
                        name: `BUKU PESANAN (ORDER BOOK)`,
                        content: `Buku Pesanan adalah buku elektronik yang menunjukkan permintaan bagi saham syarikat pada pelbagai harga dalam masa-nyata.`
                    },

                    ]
                },
                {
                    letter: 'C',
                    data: []
                },
                {
                    letter: 'D',
                    data: [{
                        name: `DELTA (DELTA)`,
                        content: `Delta adalah nisbah perubahan waran dengan perubahan harga aset pendasar. Ini menunjukkan perubahan mutlak dalam harga waran, jika harga aset pendasar berubah.<br/><br/>

                                Kebiasaannya, julat Delta adalah antara 0 dan 1 untuk waran panggilan dan 0 dan -1 untuk waran put. Sebagai contoh (menganggap waran dengan nisbah penukaran 1) delta 0.50 menunjukkan bahawa jika nilai aset pendasar berubah sebanyak 20 sen, maka nilai waran panggilan harus berubah sebanyak 10 sen.`
                    },
                    {
                        name: `DISKAUN (DISCOUNT)`,
                        content: `Jumlah di mana harga saham disebut di bawah nilai berbayarnya.`
                    },
                    {
                        name: `DANA DIDAGANGKAN BURSA (ETF) (EXCHANGE TRADED FUNDS (ETF))`,
                        content: `ETF ialah akronim bagi produk kewangan inovatif yang dikenali sebagai Dana Didagangkan Bursa, merupakan dana pelaburan terbuka disenaraikan dan didagangkan dalam bursa saham, yang menggabungkan ciri-ciri dana indeks dan saham.<br/><br/>
                            
                                Secara amnya ,terdapat tiga jenis ETFs: ETFs ekuiti, ETF pendapatan tetap dan ETFs komoditi. ETFs ini terdiri daripada kumpulan saham, bon atau komoditi berasaskan suatu indeks yang secara serta-merta menawarkan kepelbagaian luas dan menghindarkan risiko yang berkaitan dengan pemilihan saham sesebuah syarikat tunggal.<br/><br/>
                                
                                Kecairan sesuatu RTF menunjukkan kecairan bagi kumpulan saham asas.`
                    },
                    {
                        name: `DALAM NILAI LAKSANA (IN-THE-MONEY)`,
                        content: `Opsyen pada nilai laksana mempunyai nilai intrinsik lebih daripada sifar.<be/><br/>
                            
                                Untuk waran panggilan, ia dikatakan dalam nilai laksana jika Harga Pendasar > Harga Laksana;<br/>
                                Untuk waran put, ia dikatakan dalam nilai laksana jika Harga Laksana > Harga Pendasar.`
                    },
                    {
                        name: `DILUAR NILAI LAKSANA (OUT-OF-THE-MONEY)`,
                        content: `Diluar Nilai Laksana mempunyai nilai intrinsik sama dengan sifar.<br/><br/>
                            
                                Untuk waran panggilan, ia dikatakan diluar nilai laksana jika Harga Pendasar < Harga Laksana;<br/>
                                Untuk waran put, ia dikatakan diluar nilai laksana jika Harga Laksana < Harga Pendasar.`
                    },
                    ]
                },
                {
                    letter: 'E',
                    data: []
                },
                {
                    letter: 'F',
                    data: []
                },
                {
                    letter: 'G',
                    data: [{
                        name: `GAMMA (GAMMA)`,
                        content: `Mengukur perubahan delta waran dengan perubahan harga aset pendasar, sementara semua faktor lain tetap sama.`
                    },
                    {
                        name: `GAYA EROPAH (EUROPEAN-STYLE)`,
                        content: `Waran yang secara automatik dilaksanakan pada tarikh luput yang telah ditentukan.`
                    },
                    {
                        name: `GAYA AMERIKA (AMERICAN-STYLE)`,
                        content: `Waran yang boleh dilaksanakan pada bila-bila hari pasaran hingga dan termasuk tarikh luput.`
                    },
                    ]
                },
                {
                    letter: 'H',
                    data: [{
                        name: `HARGA TERTINGGI (DAY'S HIGH/ HIGH PRICE)`,
                        content: `Harga Tertinggi merujuk kepada harga perdagangan tinggi intraday untuk sekuriti tersebut. Harga tinggi menunjukkan harga tertinggi saham yang diperdagangkan sepanjang satu hari aktiviti perdagangan.`
                    },
                    {
                        name: `HARGA TAWARAN (ASK PRICE)`,
                        content: `Harga tawaran adalah harga paling rendah yang sanggup dijual oleh penjual sesuatu sekuriti.`
                    },
                    {
                        name: `HARGA BIDAAN (BID PRICE)`,
                        content: `Harga bidaan adalah harga tertinggi yang sanggup dibeli oleh pembeli untuk membeli sekuriti tertentu.`
                    },
                    {
                        name: `HASIL DIVIDEN (DIVIDEND YIELD)`,
                        content: `Jumlah dividen tahunan syarikat yang dinyatakan sebagai peratusan harga semasa saham syarikat itu.`
                    },
                    {
                        name: `HARGA LAKSANA (EXERCISE PRICE/LEVEL)`,
                        content: `Harga yang telah ditentukan pada pemegang waran untuk membeli atau menjual aset pendasar sesuatu waran. Ia digunakan untuk mengira jumlah penyelesaian tunai pada tarikh matang.`
                    },
                    {
                        name: `HARI PENYENARAIAN (LISTING DAY)`,
                        content: `Hari yang waran disenaraikan dan mempunyai harga bida dan tawar di pasaran saham.`
                    },
                    {
                        name: `HARGA TERAKHIR (LAST PRICE)`,
                        content: `Merujuk kepada harga terakhir diperdagangkan oleh sekuriti tersebut. Harga terakhir menandakan kepada titik harga di mana pembeli dan penjual telah melakukan transaksi yang terkini.`
                    },
                    {
                        name: `HARI PERDAGANGAN TERAKHIR (LAST TRADING DAY)`,
                        content: `Tiga (3) hari pasaran sebelum tarikh waran tamat.`
                    },
                    {
                        name: `HARGA BUKA (OPEN PRICE)`,
                        content: `Harga buka adalah harga yang pertama sekali sekuriti di pasaran diperdagangkan pada setiap hari perdagangan. Contohnya, Bursa Malaysia dibuka tepat jam 9:00 pagi GMT +8. Harga perdagangan pertama yang dipadankan untuk mana-mana saham tersenarai adalah harga buka harian saham tersebut.`
                    },
                    {
                        name: `HAK (RIGHTS ISSUE)`,
                        content: `Syarikat mengumpul modal tambahan dengan menawarkan kepada para pemegang saham sedia ada hak untuk melanggan saham baru, dengan harga biasanya di bawah harga pasaran semasa. Hak-hak ini mempunyai pasarannya tersendiri dan boleh didagangkan di mana-mana bursa saham.`
                    },
                    {
                        name: `HARGA KUAT KUASA (STRIKE PRICE)`,
                        content: `Rujuk HARGA LAKSANA di atas.`
                    },
                    {
                        name: `HARGA PENUTUPAN TEORI (TOP) (THEORETICAL CLOSING PRICE (TCP))`,
                        content: `Harga di mana instrumen akan diperdagangkan jika ia berhenti berdagang pada saat harga dikira. TCP dikira dalam masa-nyata pada Fasa Pra-Penutup/Penutupan.`
                    },
                    {
                        name: `HARGA PEMBUKAAN TEORI (TOP) (THEORETICAL OPENING PRICE (TOP))`,
                        content: `Harga di mana instrumen akan diperdagangkan jika dibuka pada saat ini harga dikira. TOP dikira dalam masa-nyata pada Fasa Pra Pembukaan.`
                    },
                    {
                        name: `HARGA PURATA BERWAJARAN ISI PADU (VWAP) (VOLUME WEIGHTED AVERAGE PRICE (VWAP))`,
                        content: `VWAP bagi sesuatu saham dikira dengan menambahkan nilai yang diperdagangkan untuk setiap transaksi di atas saham tersebut ("harga" x "jumlah saham yang diperdagangkan") dan kemudian dibahagikan dengan jumlah saham yang diperdagangkan. VWAP boleh dikira diantara pelbagai tempoh.`
                    }
                    ]
                },
                {
                    letter: 'I',
                    data: [{
                        name: `INDEKS (INDICES)`,
                        content: `Indeks pasaran saham ialah nombor tunggal yang dikira daripada harga banyak saham berbeza. Ia adalah instrumen yang membolehkan pelabur mengukur prestasi kumpulan saham daripada pasaran yang ditentukan.`
                    },
                    {
                        name: `INSTRUMEN (INSTRUMENT)`,
                        content: `Aset dagangan atau item yang boleh runding seperti sekuriti, komoditi, derivatif, indeks, atau sebarang item yang mendasari derivatif. Instrumen adalah satu cara yang di mana sesuatu yang bernilai boleh dipindah milik atau dimiliki.`
                    },
                    {
                        name: `"INTRADAY" HARGA TERENDAH (INTRADAY'S LOW/LOW PRICE)`,
                        content: `Intraday rendah merujuk kepada harga terendah satu sekuriti yang berdagang pada hari perdagangan tertentu. Ia boleh lebih rendah daripada harga penutupan dan juga dikenali sebagai harga terendah.`
                    }
                    ]
                },
                {
                    letter: 'J',
                    data: [{
                        name: `JUMLAH TAWARAN (ASK VOLUME)`,
                        content: `Jumlah tawaran adalah kuantiti sekuriti yang sanggup dijual oleh penjual dengan harga tawaran yang dinyatakan. Jumlah tawaran dinyatakan sebagai lot papan dengan setiap lot papan mewakili 100 unit (saham).`
                    },
                    {
                        name: `JUMLAH BIDAAN (BID VOLUME)`,
                        content: `Jumlah bidaan adalah kuantiti sekuriti yang sanggup dibeli oleh pembeli dengan harga bidaan yang dinyatakan. Jumlah bidaan dinyatakan sebagai lot papan dengan setiap lot papan mewakili 100 unit (saham).`
                    },
                    {
                        name: `JUMLAH DAGANGAN (VOLUME TRADED)`,
                        content: `Jumlah Dagangan merujuk kepada jumlah keseluruhan transaksi jual beli saham atau waran di antara pelabur sepanjang waktu pasaran saham di buka.`
                    }
                    ]
                },
                {
                    letter: 'K',
                    data: [{
                        name: `KEMERUAPAN TERSIRAT (IMPLIED VOLATILITY)`,
                        content: `Faktor terpenting yang mempengaruhi harga waran berstruktur. Ini merujuk kepada anggaran turun naik harga aset pendasar pada masa akan datang dan ia digunakan oleh pasaran sebagai petunjuk untuk memutuskan adakah waran tersebut murah atau mahal. Dengan anggapan semua faktor lain tetap sama, semakin tinggi turun naik tersirat, semakin tinggi harga waran.<br/><br/>
                            
                                Sebagai contoh, RM1.00 SW yang berubah-ubah pada RM0.10 mempunyai turun naik tersirat yang lebih tinggi daripada RM1.00 SW yang berubah-ubah pada RM0.01.`
                    },
                    {
                        name: `KECAIRAN PASARAN (LIQUIDITY OF THE MARKET)`,
                        content: `Keadaan dalam pasaran saham di mana ia biasanya mudah untuk menukar sekuriti menjadi wang tunai dan sebaliknya, tanpa menyebabkan pergerakan dalam harga.`
                    },
                    {
                        name: `KECAIRAN WARAN BERSTRUKTUR (STRUCTURED WARRANT LIQUIDITY)`,
                        content: `Waran berstruktur dianggap cair apabila terdapat:<br/><br/>
                            
                                i. Jumlah dagangan yang mencukupi; dan / atau<br/>
                                ii. Kedalaman bidaan dan tawaran harga yang mencukupi; dan / atau<br/>
                                iii. Kuantiti semasa waktu perdagangan.`
                    },
                    {
                        name: `KUANTITI WARAN TERTUNGGAK (OUTSTANDING WARRANT QUANTITY)`,
                        content: `Kuantiti waran tertunggak merujuk kepada kuantiti waran yang dipegang oleh pelabur. Lindung nilai oleh penerbit hanya akan dilakukan untuk kedudukan yang tertunggak. Semakin tinggi kuantiti tertunggak, semakin tinggi kemungkinan harga waran dipengaruhi oleh pergerak harga di pasaran saham. Oleh itu, harga waran mungkin tidak akan dapat menjejaki harga aset pendasar dengan baik.<br/><br/>
                            
                                Dalam Malaysia, untuk memerhatikan jumlah waran tertunggak bagi waran yang diterbitkan oleh MIBB untuk waran tertentu (disediakan pada akhir bulan), pelabur boleh merujuk ke tab Maklumat Pasaran di bawah Pengumuman Waran Berstruktur di laman web Bursa Malaysia.`
                    },
                    {
                        name: `KEMERUAPAN MASA LALU (HISTORICAL VOLATILITY)`,
                        content: `Digunakan sebagai rujukan untuk menilai turun naik harga aset pendasar pada masa lalu. Biasanya dinyatakan dalam jumlah hari, contohnya kemeruapan 10-hari, kemeruapan 90-hari dan lain-lain.`
                    },
                    {
                        name: `KEMERUAPAN/KETIDAKTENTUAN (VOLATILITY)`,
                        content: `Ketidaktentuan pergerakan harga yang akan mempengaruhi pulangan dari aset pendasar.<br/><br/>

                                Rujuk VOLATILITI SEJARAH dan VOLATILITI TERSIRAT`
                    },
                    {
                        name: `KALKULATOR WARAN (WARRANT CALCULATOR)`,
                        content: `Alat kalkulator waran membolehkan pelabur mengukur harga teori waran tertentu berdasarkan beberapa input. Input yang diperlukan adalah seperti harga pendasar, tarikh hingga matang dan/atau kemeruapan tersirat.`
                    },
                    ]
                },
                {
                    letter: 'L',
                    data: [{
                        name: `"LIVE MATRIX" (LIVE MATRIX)`,
                        content: `Live matrix adalah saluran matrik harga secara langsung dari sistem pembuat pasaran Maybank Investment Bank. Ini membolehkan pelabur melihat harga bidaan dan tawaran untuk waran berdasarkan pelbagai titik harga dalam stok atau indeks.<br/><br/>

                                Harga waran dalam Live Matrix adalah sebagai petunjuk. Harga waran sebenar di pasaran mungkin berbeza dengan harga yang dipaparkan di laman web oleh sebab beberapa faktor.`
                    },
                    {
                        name: `LINDUNG NILAI (HEDGE)`,
                        content: `Lindung nilai adalah satu strategi yang digunakan untuk mengurangkan risiko pergerakan harga aset yang mungkin akan mendatangkan kerugian. Biasanya, cara melindungi nilai adalah dengan membeli aset yang mempunyai kedudukan yang bertentangan dengan sekuriti yang ada di dalam portfolio.`
                    },
                    {
                        name: `LOT GANJIL (ODD LOTS)`,
                        content: `Ini adalah jumlah saham yang lebih kecil berbanding unit yang biasa diniagakan di Malaysia. Satu kuantiti yang kurang daripada 100 unit saham yang diurus niagakan ataupun transaksi saham yang tidak boleh dibahagikan dengan 100.`
                    },
                    {
                        name: `LOT DAGANGAN (BOARD LOT)`,
                        content: `Jumlah minimum waran untuk diperdagangkan di Bursa Malaysia. 1 lot dagangan = 100 unit waran.`
                    },
                    ]
                },
                {
                    letter: 'M',
                    data: [{
                        name: `MODEL "BLACK-SCHOLES (BLACK-SCHOLES MODEL)"`,
                        content: `Salah satu model penentuan harga opsyen yang paling biasa digunakan.`
                    },]
                },
                {
                    letter: 'N',
                    data: [{
                        name: `NISBAH PERTUKARAN (EXERCISE RATIO)`,
                        content: `Jumlah waran yang diperlukan untuk ditukarkan kepada satu unit saham pendasar. `
                    },
                    {
                        name: `NIAGA HADAPAN (FUTURES)`,
                        content: `Niaga hadapan adalah kontrak kewangan yang mengikat pembeli supaya membayar untuk sesuatu aset atau penjual bagi menjualkan asetnya.`
                    },
                    {
                        name: `NILAI INTRINSIK (INTRINSIC VALUE)`,
                        content: `Nilai intrinsik sesuatu waran adalah perbezaan di antara harga saham pendasar dan nilai laksana bagi waran tersebut. Nilai intrinsik boleh menjadi sifar tetapi tidak boleh menghasilkan nombor negatif.`
                    },
                    {
                        name: `NILAI MASA (NILAI MASA)`,
                        content: `Perbezaan antara harga waran dan nilai intrinsik.`
                    }
                    ]
                },
                {
                    letter: 'O',
                    data: []
                },
                {
                    letter: 'P',
                    data: [{
                        name: `PREMIUM (PREMIUM)`,
                        content: `Mengukur peratusan aset pendasar harus naik (Waran Panggilan) atau jatuh (Waran Put) agar pemegang waran menjadi pulang modal jika dia memegang waran sehingga tarikh luput.`
                    },
                    {
                        name: `PROSPEKTUS WARAN (WARRANTS PROSPECTUS ("BASE PROSPECTUS"))`,
                        content: `Dokumen yang diterbitkan oleh syarikat yang bercadang untuk menerbitkan saham kepada orang awam.`
                    },
                    {
                        name: `"PROXY" (PROXY)`,
                        content: `Seseorang yang diberikan pengesahan bertulis untuk mengundi untuk dan bagi pihak pemegang saham pada mesyuarat syarikat.`
                    },
                    {
                        name: `PADA NILAI LAKSANA (AT-THE-MONEY)`,
                        content: `Situasi di mana harga laksana waran dekat atau sama dengan harga aset pendasar. Waran didalam nilai laksana tidak mempunyai nilai intrinsik, tetapi mungkin masih mempunyai nilai waktu (bergantung pada masa yang tinggal sehingga matang).
                                Sebagai contoh, jika saham XYZ diperdagangkan pada harga RM2.00, maka opsyen panggilan atau opsyen put dengan harga laksana RM2.00 dikatakan didalam nilai laksana.`
                    },
                    {
                        name: `PASARAN MENURUN (BEAR MARKET)`,
                        content: `Sebuah pasaran saham dikuasai penjual, mengakibatkan penurunan harga secara keseluruhan.`
                    },
                    {
                        name: `PASARAN MENAIK (BULL MARKET)`,
                        content: `Sebuah pasaran saham dikuasai pembeli, mengakibatkan peningkatan harga secara keseluruhan.`
                    },
                    {
                        name: `PENYELESAIAN TUNAI (CASH SETTLEMENT AMOUNT)`,
                        content: `Amaun positif yang diterima daripada pelaksanaan waran pada tarikh matang. Sekiranya Jumlah Penyelesaian Tunai kurang dari atau sama dengan sifar, pemegang warran tidak berhak menerima pembayaran sehubungan dengan waran berstruktur.`
                    },
                    {
                        name: `PENDAPATAN PER SAHAM (EARNINGS PER SHARE)`,
                        content: `Jumlah keuntungan atau pendapatan tahunan syarikat yang boleh diagihkan kepada setiap saham biasa syarikat itu.`
                    },
                    {
                        name: `PENGGEARAN EFEKTIF (EFFECTIVE GEARING)`,
                        content: `Menunjukkan peratus perubahan dalam harga waran berbanding dengan perubahan 1% dalam pendasar. Sebagai contoh, gearing efektif 10 kali, faktor lain kekal sama, bermaksud untuk setiap perubahan 1% pada harga pendasar, harga waran bergerak sebanyak 10%.`
                    },
                    {
                        name: `PENGGEARAN (GEARING)`,
                        content: `Pendedahan tambahan kepada aset pendasar yang diperolehi berdasarkan pembelian waran. Penggearan adalah nisbah diantara harga aset pendasar dan harga waran berstruktur. Ia menandakan tahap leveraj sesuatu waran.<br/><br/>
                            
                                Sebagai contoh, waran dengan gearing 10x akan mempunyai pendedahan 10 kali ganda lebih banyak daripada jika anda membeli aset pendasar. Untuk menganggarkan kenaikan atau penurunan harga waran relatif terhadap harga aset pendasar, kita harus melihat gearing efektif.`
                    },
                    {
                        name: `PERGERAKAN INTRADAY (INTRADAY MOVEMENT)`,
                        content: `Intraday didefinisikan sebagai dalam masa sehari. Pergerakan intraday merujuk kepada pergerakan harga sekuriti tertentu sepanjang satu hari aktiviti perdagangan. Ini adalah untuk menyatakan harga tinggi dan rendah untuk saham tersebut pada hari dagangan yang tertentu.`
                    },
                    {
                        name: `PENERBIT (ISSUER)`,
                        content: `Entiti yang mengeluarkan waran, biasanya institusi kewangan, misalnya Maybank Investment Bank Berhad.`
                    },
                    {
                        name: `PESANAN TERHAD (PESANAN TERHAD)`,
                        content: `Untuk mengelak daripada membeli atau menjual saham pada harga lebih tinggi atau rendah daripada yang dikehendaki, pesanan terhad patut ditetapkan. Pesanan terhad ialah pesanan untuk membeli atau menjual instrumen pada harga tertentu. Pesanan had beli hanya boleh dilaksanakan pada harga had atau lebih rendah, dan perintah had jual hanya boleh dilaksanakan pada harga had atau lebih tinggi. Apabila pesanan pasaran dibuat, harga di mana pesanan akan diisi tidak dapat dikawal.`
                    },
                    {
                        name: `PENYEDIA KECAIRAN (LIQUIDITY PROVIDER)`,
                        content: `Rujuk PEMBUAT PASARAN di bawah.`
                    },
                    {
                        name: `PERMODALAN PASARAN (MARKET CAPITALISATION)`,
                        content: `Jumlah nilai saham sesebuah syarikat tersenarai berdasarkan pada harga pasaran semasa.`
                    },
                    {
                        name: `PENGGERAK PASARAN/PEMBUAT PASARAN (MARKET MAKER)`,
                        content: `Pembuat pasaran menyediakan kecairan dengan menyatakan harga bidaan dan tawaran pada sistem perdagangan Bursa Sekuriti pada setiap hari pasaran sepanjang tempoh hayat waran berstruktur. Pembuat pasaran boleh terdiri dari penerbit itu sendiri (contohnya Maybank Investment Bank Berhad) ataupun pembuat pasaran yang dilantik.`
                    },
                    {
                        name: `PESANAN PASARAN (MARKET ORDER)`,
                        content: `Pesanan pasaran adalah pesanan untuk membeli atau menjual saham pada harga pasaran semasa.  Kelebihan pesanan pasaran adalah ia selalunya dijamin bahawa pesanan akan dilaksanakan (selagi terdapat pembeli dan penjual yang bersedia). Bergantung kepada struktur komisen sesebuah firma, pesanan pasaran mungkin juga lebih murah daripada pesanan berhad.`
                    },
                    {
                        name: `PERBELAHAN SAHAM (SHARE SPLIT)`,
                        content: `Apabila sesebuah syarikat merendahkan harga pasaran sahamnya dengan menambah jumlah saham yang tertunggak di dalam pasaran, contohnya seperti 100,000 RM 2 saham biasa akan dibahagikan kepada 200,000 RM 1 saham biasa.`
                    },
                    {
                        name: `PESANAN BERHENTI (PESANAN BERHENTI)`,
                        content: `Perintah Berhenti menjadi pesanan pasaran untuk membeli atau menjual sekuriti setelah harga berhenti yang ditentukan dicapai atau ditembusi.`
                    },
                    {
                        name: `PEMEGANG WARAN (WARRANTHOLDER)`,
                        content: `Nama individu yang muncul dalam Rekod Penyimpan untuk waran berstruktur buat masa ini.`
                    },
                    ]
                },
                {
                    letter: 'Q',
                    data: []
                },
                {
                    letter: 'R',
                    data: [{
                        name: `REMISER (REMISIER)`,
                        content: `Ejen bagi syarikat broker saham yang membawa pelabur saham untuk menggunakan perkhidmatan syarikat terbabit dan sebagai bayaran, ejen tersebut akan mendapat komisen.`
                    },

                    ]
                },
                {
                    letter: 'S',
                    data: [{
                        name: `SEKURITI PATUH SYARIAH (SHARIAH-COMPLIANT SECURITIES)`,
                        content: `Sekuriti patuh Syariah adalah sekuriti syarikat awam tersenarai di pasaran saham, yang telah diklasifikasikan sebagai patuh Syariah.`
                    },
                    {
                        name: `SHORT SELLING (SHORT SELLING)`,
                        content: `Tindakan seseorang yang menjual saham yang tidak dimilikinya ketika masa jualan.`
                    },
                    {
                        name: `SIJIL BULL/SIJIL BEAR (CALLABLE BULL/BEAR CONTRACTS (CBBC))`,
                        content: `CBBC menjejaki prestasi saham pendasar tanpa memerlukan pelabur membayar harga penuh yang diperlukan untuk memiliki saham sebenar. la diterbitkan sama ada dalam bentuk Sijil Bull atau Sijil Bear dengan tarikh tamat yang tetap, yang membolehkan pelabur mengambil kedudukan menaik atau menurun atas saham pendasar, dengan kemungkinan penamatan awal sebelum tarikh tamat apabila pendasar bergerak ke arah yang bertentangan dengan jangkaan pelabur.`
                    },
                    {
                        name: `SAHAM MEMBANGUN (GROWTH STOCKS)`,
                        content: `Saham syarikat yang mempunyai prospek yang baik untuk meningkatkan keuntungan dan saiz modal. Berkemungkinan untuk mendatangkan keuntungan kepada para pemegang saham melalui kenaikan harga saham, dividen yang tinggi, bonus saham atau terbitan hak.`
                    },
                    {
                        name: `STATUS WARAN (MONEYNESS) (MONEYNESS)`,
                        content: `Hubungan antara harga laksana dan harga aset pendasar. Terdapat tiga kategori bagi status waran, iaitu Pada Nilai Laksana, Didalam Nilai Laksana dan Diluar Nilai Laksana.`
                    },
                    {
                        name: `SEBUT HARGA (QUOTATION)`,
                        content: `Harga bidaan atau tawaran oleh pembeli dan penjual untuk sekuriti yang disenaraikan di bursa saham.`
                    },
                    {
                        name: `SUSUTAN NILAI MASA (TIME DECAY)`,
                        content: `Susutan Nilai Masa adalah disebabkan oleh penurunan Nilai masa yang seiring dengan pergerakan Waran ke arah tarikh matang.`
                    },
                    ]
                },
                {
                    letter: 'T',
                    data: [

                        {
                            name: `TRANSAKSI WAKTU AKHIR (TWA) (TRADING AT LAST (TAL))`,
                            content: `Pesanan di TWA hanya akan dimasukkan dan dipadankan dengan Harga Penutup. Hanya pesanan had yang dibenarkan dan sistem akan menolak sebarang Pesanan Pasar.`
                        },
                        {
                            name: `THETA (THETA)`,
                            content: `Mengukur jangkaan penurunan nilai waran harian kerana susutan nilai masa, dengan anggapan semua faktor lain kekal sama. Theta akan sentiasa negatif kerana susutan nilai masa sentiasa gerak bertentang dengan pemegang waran.`
                        },
                        {
                            name: `TARIKH LUPUT (EXPIRY DATE)`,
                            content: `Tarikh waran tamat; menandakan hak pemegang waran untuk melaksanakan waran itu telah tamat.`
                        },
                        {
                            name: `TAWARAN AWAM PERMULAAN (IPO) (INITIAL PUBLIC OFFERING (IPO))`,
                            content: `Tawaran awam permulaan (IPO) berlaku apabila sesebuah syarikat persendirian pertama kali menjual sebahagian milikan syarikatnya kepada orang awam. Syarikat yang menawarkan IPO perlu mengupah peguam korporat dan juga sebuah bank pelaburan untuk menanggung jamin tawaran awam permulaan tersebut. Apabila sebuah syarikat mula menawarkan sahamnya kepada orang awam, ia dikehendaki untuk mendedahkan maklumat kewangan syarikat supaya pelabur dapat membuat keputusan yang sewajarnya.`
                        },
                        {
                            name: `TERBITAN BONUS (BONUS ISSUE)`,
                            content: `Terbitan Bonus adalah saham yang diedarkan oleh syarikat kepada pemegang sahamnya sebagai saham yang dibayar penuh secara percuma. Selain daripada pembayaran dividen, syarikat menawarkan untuk mengagihkan saham tambahan kepada pemegang saham. Sebagai contoh, syarikat boleh memutuskan untuk memberikan satu saham bonus untuk setiap sepuluh saham yang dipegang oleh pemegang saham.`
                        },
                        {
                            name: `TAHAP PULANG MODAL (BREAK-EVEN LEVEL)`,
                            content: `Merujuk pada harga di mana transaksi tidak menghasilkan keuntungan atau kerugian.`
                        }

                    ]
                },
                {
                    letter: 'U',
                    data: []
                },
                {
                    letter: 'V',
                    data: []
                },
                {
                    letter: 'W',
                    data: [{
                        name: `WARAN (WARRANTS)`,
                        content: `Waran memberikan pemegang hak tetapi bukan kewajiban untuk membeli sekuriti yang mendasari pada harga, kuantiti tertentu.`
                    },

                    {
                        name: `WAKTU PERDAGANGAN WARAN (WARRANTS TRADING HOURS)`,
                        content: `Waktu dagangan waran untuk saham FBM KLCI dan saham di Malaysia adalah dari jam 9.00 pagi - 4.44 petang, dan 9.00 pagi - 4.43 petang untuk waran untuk S&P 500.`
                    },
                    {
                        name: `WARAN PANGGILAN (CALL WARRANTS)`,
                        content: `Waran panggilan menawarkan kepada pemegangnya hak, tetapi bukan kewajiban untuk membeli sejumlah aset yang mendasari pada harga laksana yang telah ditentukan dalam waktu yang ditentukan sebelumnya.`
                    },
                    {
                        name: `WAKTU PERDAGANGAN EKUITI (EXCHANGE TRADING HOURS)`,
                        content: `Waktu perdagangan ekuiti dibahagikan kepada dua sesi untuk pasaran lot biasa dan ganjil.<br/>
                                Untuk Malaysia: Sesi pertama adalah dari 8:30 pagi - 12:30 petang, dan sesi kedua berlangsung dari 2:00 petang - 5:00 petang.<br/>
                                Untuk Hong Kong: Sesi pertama adalah dari 9:00 pagi - 12:00 petang, dan sesi kedua berlangsung dari 1:00 petang - 4:00 petang.<br/>
                                Untuk Thailand: Sesi pertama dibuka secara rawak antara jam 9:55 hingga 10:00 am - 12:30 pm, dan sesi kedua dibuka secara rawak antara 2:25 hingga 2:30 pm dan ditutup secara rawak antara jam 4:35 hingga 4:40 pm.`
                    },
                    {
                        name: `WARAN PUT`,
                        content: `Waran put menawarkan kepada pemegangnya hak, tetapi bukan kewajiban untuk menjual sejumlah aset yang mendasari pada harga laksana yang telah ditentukan dalam waktu yang ditentukan sebelumnya.`
                    }, {
                        name: `WARAN`,
                        content: `Waran memberikan pemegang hak tetapi bukan kewajiban untuk membeli atau menjual sekuriti yang mendasari pada harga, kuantiti tertentu.`
                    }

                    ]
                },
                {
                    letter: 'X',
                    data: []
                },
                {
                    letter: 'Y',
                    data: []
                },
                {
                    letter: 'Z',
                    data: []
                }
                ]
            ],
            GlossarySeek: [],
            dataindex: 0,
            letter: '',
            indexN: 0
        };
    },
    computed: {

    },
    watch: {

    },
    methods: {
        // 改变语言
        ChangeLanguage: function (index) {
            $('#Glossary').find('.btnBox').find('button').eq(index).addClass('mark')
            $('#Glossary').find('.btnBox').find('button').eq(index).siblings('button').removeClass('mark')
            this.dataindex = index
            this.relevantData(this.letter, this.indexN)
            this.GitGlossarySeek(this.GlossaryData[index])
        },
        // 点击显示相应数据
        relevantData: function (letter, index) {
            this.indexN = index
            this.letter = letter
            $('#Glossary').find('.content').find('li').find('p').slideUp();
            $('#Glossary').find('.content').find('li').find('i').addClass('bi-caret-down-fill')
            $('#Glossary').find('.content').find('li').find('i').removeClass('bi-caret-up-fill')
            $('#Glossary').find('.letterBox').find('th').eq(index).addClass('markth')
            $('#Glossary').find('.letterBox').find('th').eq(index).siblings('th').removeClass('markth')
            this.GlossaryShwoData = this.GlossaryData[this.dataindex].filter(item => item.letter == letter)
            this.GlossaryShwoData = this.GlossaryShwoData[0].data
        },
        // 获得 搜索框数据
        GitGlossarySeek: function (data) {
            this.GlossarySeek = []
            $.each(data, (i, c) => {
                $.each(c.data, (di, dc) => {
                    this.GlossarySeek.push([dc.name, i, di])
                })
            })
        },
        // 详细内容显示和隐藏
        minuteShow: function (index, ife) {
            if ($('#Glossary').find('.content').find('li').eq(index).find('i').hasClass('bi-caret-up-fill')) {
                $('#Glossary').find('.content').find('li').eq(index).find('i').addClass('bi-caret-down-fill')
                $('#Glossary').find('.content').find('li').eq(index).find('i').removeClass('bi-caret-up-fill')
            } else {
                $('#Glossary').find('.content').find('li').eq(index).find('i').addClass('bi-caret-up-fill')
                $('#Glossary').find('.content').find('li').eq(index).find('i').removeClass('bi-caret-down-fill')
            }
            console.log();
            if (ife) {
                $('#Glossary').find('.content').find('li').eq(index).find('p').slideToggle();
            } else {
                let win = $(window)
                setTimeout(function () {
                    $('#Glossary').find('.content').find('li').eq(index).find('p').slideToggle();
                    win.scrollTop($('#Glossary').find('.content').find('li').eq(index).offset().top)
                }, 10);
            }

        },
        // 出现combobox
        ariseCombobox: function (id, index) {
            let _this = this
            this.$$.jqcombobox(id)
            $(id).combobox({
                select: function (event, ui) {
                    _this.relevantData(this.value.split(',')[0].split('')[0], this.value.split(',')[1])
                    _this.minuteShow(this.value.split(',')[2])
                }
            });
            $(id).parent().find('input').attr('placeholder', 'Type to Search')
            this.$$.inputEmpty($(id).parent())
        },
    }
};